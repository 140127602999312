import clsx from "clsx";
import { H6, Paragraph } from "./ui/typography";
import BannerBgDark from "~/images/banner-dark-bg.png";
import BannerBgLight from "~/images/banner-light-bg.png";
import { ArrowLink } from "./ui/arrow-button";

export interface AdsBannerProps {
	title: string;
	children: string;
	ctaLink?: string | null;
	ctaText?: string;
	background?: string;
	backgroundPosition?: string;
	theme?: "light" | "dark";
	shouldUseRegularAnchor?: boolean;
}

export function AdsBanner({
	title,
	children,
	ctaLink,
	ctaText,
	background,
	backgroundPosition = "center right",
	theme = "light",
	shouldUseRegularAnchor = false,
}: AdsBannerProps) {
	const getDefaultBackground = () => {
		if (theme === "light") {
			return background ? background : BannerBgLight;
		}
		if (theme === "dark") {
			return background ? background : BannerBgDark;
		}
	};
	const backgroundImage = `url(${getDefaultBackground()})`;

	return (
		<div
			className={clsx(
				"relative my-layout3 flex w-full flex-col gap-6 rounded-xl bg-contain bg-no-repeat p-6 max-[1023px]:!bg-none",
				{
					"bg-secondary": theme === "light",
					"bg-grey-100": theme === "dark",
				},
				theme
			)}
			style={{
				backgroundImage,
				backgroundPosition,
			}}
		>
			<div className="w-full lg:w-2/3">
				<H6 className="text-primary mb-3">{title}</H6>
				<Paragraph
					size="body-small"
					className={clsx("mb-5", {
						"text-grey-90": theme === "light",
						"text-grey-30": theme === "dark",
					})}
				>
					{children}
				</Paragraph>
				{ctaLink ? (
					<ArrowLink
						to={ctaLink}
						size="dense"
						trackingPosition="banner"
						forceUseAnchorLink={shouldUseRegularAnchor}
					>
						{ctaText}
					</ArrowLink>
				) : null}
			</div>
		</div>
	);
}
