import React from "react";
import { clsx } from "clsx";
import { Paragraph } from "~/components/ui/typography";
import type { TitleProps } from "~/components/ui/typography";

export function BulletList({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<ul
			className={clsx(
				"my-5 list-disc pl-7 text-left marker:text-bullet",
				className
			)}
		>
			{children}
		</ul>
	);
}

export function OrderList({
	children,
	className,
	color = "primary",
}: {
	children: React.ReactNode;
	className?: string;
	color?: TitleProps["color"];
}) {
	return (
		<ol
			className={clsx(
				"my-5 list-decimal pl-7 text-left",
				{ "marker:text-primary": color === "primary" },
				{ "marker:text-secondary": color !== "primary" },
				className
			)}
		>
			{children}
		</ol>
	);
}

export function ListItem({
	children,
	className,
	color = "primary",
	id,
}: {
	children: React.ReactNode;
	className?: string;
	color?: TitleProps["color"];
	id?: string;
}) {
	return (
		<Paragraph
			id={id}
			as="li"
			whiteSpacePreLine={false}
			color={color}
			className={clsx("mb-5 last:mb-0", className)}
		>
			{children}
		</Paragraph>
	);
}
