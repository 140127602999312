import clsx from "clsx";
import { Link as RemixLink } from "@remix-run/react";
import type { LinkProps as RemixLinkProps } from "@remix-run/react";
import { spTrackLink } from "~/utils/tracking";

export interface LinkProps extends RemixLinkProps {
	trackingText?: string;
	trackingPosition?: string;
	forceUseAnchorLink?: boolean;
}

export function Link({
	children,
	trackingText,
	trackingPosition,
	prefetch = "intent",
	to,
	className,
	reloadDocument,
	forceUseAnchorLink = false,
	...rest
}: LinkProps) {
	let toUrl = "";

	function handleOnClick(e: React.MouseEvent<HTMLAnchorElement>) {
		spTrackLink({
			url: e.currentTarget.href,
			text: trackingText || e.currentTarget.text,
			position: trackingPosition,
		});
	}

	let shouldUseRegularAnchor = false;

	if (typeof to === "string") {
		toUrl = to;
		shouldUseRegularAnchor =
			toUrl.startsWith("http") ||
			toUrl.startsWith("#") ||
			toUrl.startsWith("mailto");
	}

	if (typeof to === "object") {
		toUrl = `${to.pathname ?? ""}${to.hash ? `#${to.hash}` : ""}${
			to.search ? `?${to.search}` : ""
		}`;

		shouldUseRegularAnchor = to.pathname?.startsWith("http") || true;
	}

	if (forceUseAnchorLink || shouldUseRegularAnchor) {
		return (
			<a
				className={clsx(className, "[&>*]:pointer-events-none")}
				href={toUrl}
				onClick={handleOnClick}
				{...rest}
			>
				{children}
			</a>
		);
	}

	return (
		<RemixLink
			className={clsx(className, "[&>*]:pointer-events-none")}
			prefetch={prefetch}
			reloadDocument={reloadDocument}
			to={to}
			onClick={handleOnClick}
			{...rest}
		>
			{children}
		</RemixLink>
	);
}

interface ConditionalLinkProps extends LinkProps {
	condition: boolean;
	as?: React.ElementType;
}

export const ConditionalLink: React.FunctionComponent<ConditionalLinkProps> = ({
	children,
	to,
	condition,
	className,
	as,
	...rest
}) => {
	const Tag = as ?? "div";

	return condition ? (
		<Link prefetch="intent" to={to} className={clsx(className)} {...rest}>
			{children}
		</Link>
	) : (
		<Tag className={className}>{children}</Tag>
	);
};
