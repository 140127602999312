/**
 * Type guard utility to help navigating through unknown object properties.
 * @param object - Object to check.
 * @param propertyName - Property name to check.
 * @returns True if object has property with given property name.
 */
export function hasProperty<T extends string>(
	object: unknown,
	propertyName: T
): object is Record<T, unknown> {
	if (object === null || object === undefined) {
		return false;
	}
	return Object.prototype.hasOwnProperty.call(object, propertyName);
}

export type AnalyticsId = `${string}--${string}`;
