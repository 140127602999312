function isDevelopment() {
	return process.env.NODE_ENV === "development";
}

export function getSanityConfig() {
	const dataset =
		ENV.SANITY_DATASET || (isDevelopment() ? "staging" : "production");

	return {
		projectId: "sczeoy4w",
		dataset: dataset,
		apiVersion: "2022-03-07",
	};
}
