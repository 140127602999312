import acceptLanguage from "accept-language-parser";

// const supportedLanguages = ["de", "en", "es", "fr", "ja", "ko", "pt"] as const;

export const supportedLanguages = ["en", "de", "fr"] as const;

export enum Dictionary {
	COMMON = "Common",
	CASE_STUDIES = "Case Studies",
	NEWSLETTER = "Newsletter",
	BLOG = "Blog",
	LANGUAGE = "Language",
	SERVICE_DIAGRAM = "Service Diagram",
	RESOURCES = "Resources",
	PRICING = "Pricing",
	DEVCENTER = "Developer Center",
	KPF = "Kafka Plan Finder",
	EVENT = "Event",
	CAREERS = "Careers",
}

export type Language = (typeof supportedLanguages)[number];

export function getLanguageLocaleKey(lang: Language) {
	const keys = {
		en: "English",
		de: "German",
		es: "Spanish",
		fr: "French",
		ja: "Japanese",
		ko: "Korean",
		pt: "Portuguese",
	};

	return keys[lang];
}

// `any` is valid here since technically this function works against any type of data
// but preferrable if it warns against not string data
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function validateLanguage(language: any): language is Language {
	return supportedLanguages.includes(language);
}

export function getLanguageFromUrl(urlString: string): string {
	const url = new URL(urlString);
	const [, urlLang] = url.pathname.split("/", 2);

	if (!validateLanguage(urlLang)) {
		return "en";
	}

	return urlLang;
}

export function getLocalePath(path: string, lang: string): string {
	if (!validateLanguage(lang) || lang === "en") {
		return path;
	}

	return `/${lang}${path}`;
}

export function getLanguage(input: Request): Language {
	const lang = getLanguageFromUrl(input.url);

	if (validateLanguage(lang)) {
		return lang;
	}

	const defaultLanguage = getBrowserDefaultLanguage(input);

	if (!validateLanguage(defaultLanguage)) {
		return "en";
	}

	return defaultLanguage;
}

export function getBrowserDefaultLanguage(request: Request) {
	const languages = acceptLanguage.parse(
		request.headers.get("Accept-Language") as string
	);

	// If somehow the header is empty, return a default locale
	if (languages?.length < 1) return "en";

	// If there is no region for this locale, just return the code
	if (!languages[0].region) return languages[0].code;

	return `${languages[0].code}-${languages[0].region.toLowerCase()}`;
}
