import clsx from "clsx";
import type { LinkProps } from "./link";
import { Icon } from "~/components/ui/icons";
import type { ButtonProps } from "./button";
import { IconLink } from "./icon-link";

export function ArrowLink({
	children,
	prefetch = "intent",
	className,
	to,
	size,
	...rest
}: LinkProps & {
	size?: ButtonProps["size"];
}) {
	return (
		<IconLink
			className={clsx("group/arrow", className, {
				"text-xs md:text-sm": size === "dense",
			})}
			prefetch={prefetch}
			to={to}
			iconRight={
				<Icon
					name="arrow-right"
					className="transition-all group-hover/arrow:translate-x-2"
					color="cta"
				/>
			}
			{...rest}
		>
			{children}
		</IconLink>
	);
}
