import { renderToString } from "react-dom/server";
import { MarkDown } from "~/components/markdown";
import { getImageBuilder } from "~/utils/images";
import { notEmpty } from "~/utils/misc";
import { capitalize } from "lodash";
import type {
	PageDocumentBase,
	Accordion,
	SanityKeyed,
	SimplePortableText,
	MetaData,
	EventList,
	EventPageDocument,
} from "~/types/sanity-schema";
import type { PostSingleQuery } from "~/types/queries";
import type {
	TechArticle,
	WithContext,
	FAQPage,
	Event,
	ItemList,
	JobPosting,
	ListItem,
	Thing,
	CollectionPage,
} from "schema-dts";
import { toHTML } from "@portabletext/to-html";
import { externalLinks } from "./external-links";
import {
	asLink,
	asText,
	isEventsPageDocument,
	isGlossaryArticleSingle,
	isPageDocument,
} from "~/utils/sanity-helpers";
import type { DevArticle } from "~/types/dev-center";
import type { EventDocument, TalksList } from "~/types/event";
import uniqBy from "lodash/uniqBy";
import type { Job } from "~/types/job";
import parse from "html-react-parser";
import type { GlossaryArticle } from "~/types/glossary";

type SchemaConfig = {
	pageData?: PageDocumentBase | PostSingleQuery | DevArticle | GlossaryArticle;
	baseUrl: string;
};

export function portableTextToHtml(content: SimplePortableText) {
	return toHTML(content, {
		components: {
			marks: {
				internalLink: ({ value, children }) => {
					const slug = value?.slug?.current;
					return slug
						? `
					<a href="${externalLinks.rootDomain}${value?.slug?.current}">
						${children}
					</a>
					`
						: `<span>${children}</span>`;
				},
			},
			types: {
				image: () => "",
			},
		},
		onMissingComponent: () => {
			return "";
		},
	});
}

function getSpeakersList(talksList: TalksList) {
	const speakers = talksList
		? talksList.map((talk) => talk.speakers).flat()
		: [];
	return uniqBy(speakers.flat(), "name");
}

function getEventSchema(eventData: EventPageDocument) {
	const eventStructuredData: WithContext<Event> = {
		"@context": "https://schema.org",
		"@type": "Event",
		name: asText(eventData.title),
		startDate: eventData.startDate
			? new Date(eventData.startDate).toISOString()
			: "",
		endDate: eventData.endDate ? new Date(eventData.endDate).toISOString() : "",
		eventAttendanceMode: eventData.virtualEvent
			? "https://schema.org/OnlineEventAttendanceMode"
			: "https://schema.org/OfflineEventAttendanceMode",
		eventStatus: "https://schema.org/EventScheduled",
		location: eventData.virtualEvent
			? {
					"@type": "VirtualLocation",
					url:
						eventData.contentIsPage && eventData.slug
							? eventData.slug.current
							: asLink(eventData.primaryCTA) || undefined,
				}
			: {
					"@type": "Place",
					name: eventData.location,
					address: {
						"@type": "PostalAddress",
						streetAddress: eventData.location,
						addressRegion: eventData.region,
						addressCountry: eventData.country,
					},
				},
		description:
			eventData.contentIsPage && eventData.pageType?.eventDetails
				? portableTextToHtml(eventData.pageType?.eventDetails)
				: portableTextToHtml(eventData.description),
		offers: {
			"@type": "Offer",
			url:
				eventData.contentIsPage && eventData.slug
					? eventData.slug.current
					: asLink(eventData.primaryCTA) || undefined,
		},
		keywords: eventData.tags
			? eventData.tags.map((tag) =>
					tag?.title ? capitalize(tag.title.toLocaleString()) : ""
				)
			: [],
		performer: eventData.talks
			? getSpeakersList(eventData.talks).map((speaker) => ({
					"@type": "Person",
					name: speaker?.name || undefined,
					jobTitle: speaker?.jobTitle || undefined,
					affiliation: "Aiven",
				}))
			: [],
	};

	return eventStructuredData;
}

export function getEventListSchema(eventList: Array<EventDocument>) {
	const eventListStructuredData: WithContext<ItemList> = {
		"@context": "https://schema.org",
		"@type": "ItemList",
		numberOfItems: eventList.length,
		itemListElement: eventList.map((event) => getEventSchema(event)),
	};

	return eventListStructuredData;
}

export function getJobPositionSchema(job: Job) {
	const jobDescription = parse(asText(job.content)) as string;
	const isRemote =
		job.location.name && job.location.name.toLowerCase().includes("remote");

	const jobStructuredData: WithContext<JobPosting> = {
		"@context": "https://schema.org",
		"@type": "JobPosting",
		datePosted: job.updated_at ? new Date(job.updated_at).toISOString() : "",
		description: jobDescription,
		title: asText(job.title),
		hiringOrganization: {
			"@type": "Organization",
			name: "Aiven",
			logo: {
				"@type": "ImageObject",
				url: "https://aiven.io/assets/img/aiven-logo.png",
			},
		},
	};

	if (isRemote) {
		jobStructuredData.applicantLocationRequirements = [
			{
				"@type": "Country",
				name: job.offices && job.offices[0] ? job.offices[0].name : undefined,
			},
		];
		jobStructuredData.jobLocationType = "TELECOMMUTE";
	} else {
		jobStructuredData.jobLocation = [
			{
				"@type": "Place",
				address: {
					"@type": "PostalAddress",
					addressLocality: job.location.name,
				},
			},
		];
	}

	return jobStructuredData;
}

export function getCareersJobPageSchema(jobs: Job[], seoData: MetaData) {
	function returnListItemSchema(
		job: Job,
		index: number
	): WithContext<ListItem> {
		return {
			"@type": "ListItem",
			"@context": "https://schema.org",
			position: index,
			item: getJobPositionSchema(job),
		};
	}
	const listStructuredData: WithContext<ItemList> = {
		"@context": "https://schema.org",
		"@type": "ItemList",
		name: seoData.metaTitle,
		numberOfItems: jobs.length ? jobs.length : undefined,
		description: jobs.length
			? seoData.metaDescription
			: "There is currently no job opening.",
		itemListElement: jobs.length
			? jobs.map((job, index) => returnListItemSchema(job, index))
			: undefined,
	};

	return listStructuredData;
}

function getArticleSchema(articleData: DevArticle, baseUrl: string) {
	const structuredData = articleData.seo?.metaStructuredData;
	if (structuredData) {
		try {
			return JSON.parse(structuredData) as WithContext<Thing>;
		} catch (error) {
			console.error("Fail to parse structured data: ", error);
			return undefined;
		}
	}
	// This `currentUrl` for @id can be hardcoded
	// as long as we have one domain and similar blog posts are parts of this domain.
	// Basically it's just an ID for a node that can be referenced to by other nodes, so
	// as long as it's unique and relevant it's OK
	// More details here: https://stackoverflow.com/questions/34761970/schema-org-json-ld-reference/34776122#34776122
	const currentUrl = `${baseUrl || externalLinks.rootDomain}${
		articleData.slug.current
	}`;

	const { body = "", publishedAt = "", tags = [] } = articleData;

	const htmlContent = renderToString(
		<MarkDown shouldUseRemixRelatedContext={false} content={asText(body)} />
	);

	const defaultSchema: WithContext<TechArticle> = {
		"@context": "https://schema.org",
		"@type": "TechArticle",
		mainEntityOfPage: {
			"@type": "WebPage",
			"@id": currentUrl,
		},
		headline: asText(articleData.title),
		// Date format is different for published and updated dates for some reason ¯\_(ツ)_/¯
		datePublished: new Date(publishedAt).toISOString(),
		dateModified: articleData._updatedAt
			? new Date(articleData._updatedAt).toISOString()
			: "",
		publisher: {
			"@type": "Organization",
			name: "Aiven",
			logo: {
				"@type": "ImageObject",
				url: "https://aiven.io/assets/img/aiven-logo.png",
			},
		},
		description: articleData?.seo?.metaDescription,
		url: currentUrl,
		wordCount: htmlContent.replace(/<[^>]*>?/gm, "").split(" ").length,
		proficiencyLevel: "Beginner",
		inLanguage: articleData.__i18n_lang,
		articleBody: htmlContent,
		keywords: tags
			? tags.map((tag) => (tag?.title ? capitalize(tag.title) : ""))
			: [],
	};

	return defaultSchema;
}

function getBlogSchema(blogData: PostSingleQuery, baseUrl: string) {
	const structuredData = blogData.seo?.metaStructuredData;
	if (structuredData) {
		try {
			return JSON.parse(structuredData) as WithContext<Thing>;
		} catch (error) {
			console.error("Fail to parse structured data: ", error);
			return undefined;
		}
	}
	// This `currentUrl` for @id can be hardcoded
	// as long as we have one domain and similar blog posts are parts of this domain.
	// Basically it's just an ID for a node that can be referenced to by other nodes, so
	// as long as it's unique and relevant it's OK
	// More details here: https://stackoverflow.com/questions/34761970/schema-org-json-ld-reference/34776122#34776122
	const currentUrl = `${baseUrl || externalLinks.rootDomain}${
		blogData.slug.current
	}`;

	const {
		body = "",
		publishedAt = "",
		authors = [],
		categories = [],
	} = blogData;

	// https://developers.google.com/search/docs/data-types/article#article-types
	const schemaImage1x1 = getImageBuilder(blogData?.seo?.metaImage)
		?.quality(100)
		.width(696)
		.height(696)
		.url();
	const schemaImage4x3 = getImageBuilder(blogData?.seo?.metaImage)
		?.quality(100)
		.width(696)
		.height(522)
		.url();
	const schemaImage16x9 = getImageBuilder(blogData?.seo?.metaImage)
		?.quality(100)
		.width(696)
		.height(392)
		.url();

	const images = [schemaImage1x1, schemaImage4x3, schemaImage16x9].filter(
		notEmpty
	);

	const htmlContent = renderToString(
		<MarkDown shouldUseRemixRelatedContext={false} content={body} />
	);

	const defaultSchema: WithContext<TechArticle> = {
		"@context": "https://schema.org",
		"@type": "TechArticle",
		mainEntityOfPage: {
			"@type": "WebPage",
			"@id": currentUrl,
		},
		headline: blogData.title,
		image: images,
		// Date format is different for published and updated dates for some reason ¯\_(ツ)_/¯
		datePublished: new Date(publishedAt).toISOString(),
		dateModified: blogData._updatedAt
			? new Date(blogData._updatedAt).toISOString()
			: "",
		author: {
			"@type": "Person",
			name: authors ? authors[0]?.name : "",
			jobTitle: authors ? authors[0]?.bio || "" : "",
			affiliation: "Aiven",
		},
		publisher: {
			"@type": "Organization",
			name: "Aiven",
			logo: {
				"@type": "ImageObject",
				url: "https://aiven.io/assets/img/aiven-logo.png",
			},
		},
		description: blogData?.seo?.metaDescription,
		url: currentUrl,
		wordCount: htmlContent.replace(/<[^>]*>?/gm, "").split(" ").length,
		proficiencyLevel: "Beginner",
		inLanguage: blogData.__i18n_lang,
		articleBody: htmlContent,
		keywords: categories
			? categories.map((category) =>
					category ? capitalize(category.title) : ""
				)
			: [],
	};

	return defaultSchema;
}

function getPageSchema(pageData: PageDocumentBase) {
	try {
		const structuredData = pageData?.seo?.metaStructuredData;

		return structuredData
			? (JSON.parse(structuredData) as WithContext<Thing>)
			: undefined;
	} catch (error) {
		console.error("Fail to parse structured data: ", error);

		return undefined;
	}
}

function getFAQSchema(faqSection: SanityKeyed<Accordion>) {
	const { items } = faqSection;

	const faqSchema: WithContext<FAQPage> = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		mainEntity: items?.map((item) => ({
			"@type": "Question",
			name: item.title,
			acceptedAnswer: {
				"@type": "Answer",
				text: portableTextToHtml(item.panelText),
			},
		})),
	};

	return faqSchema;
}

function hasAuthors(authorsList?: { name: string }[]) {
	return (
		authorsList &&
		authorsList.length &&
		authorsList.every((item) => {
			return !!item && "name" in item;
		})
	);
}

type collectionListItem = {
	name: string;
	description?: SimplePortableText;
	url?: string;
	image?: string;
	datePublished?: string;
	author?: { name: string }[];
};
type CollectionPageSchemaConfig = {
	pageData?: PageDocumentBase | PostSingleQuery | DevArticle;
	baseUrl: string;
	itemsList: collectionListItem[];
	title?: string;
	description?: string;
};

export function getCollectionPageSchema({
	pageData,
	baseUrl,
	itemsList,
	title,
	description,
}: CollectionPageSchemaConfig): StructuredDataTag {
	const emptyStructuredData = {
		"script:ld+json": undefined,
	};

	if (!pageData) return emptyStructuredData;

	// https://developers.google.com/search/docs/data-types/article#article-types
	const schemaImage1x1 = getImageBuilder(pageData?.seo?.metaImage)
		?.quality(100)
		.width(696)
		.height(696)
		.url();
	const schemaImage4x3 = getImageBuilder(pageData?.seo?.metaImage)
		?.quality(100)
		.width(696)
		.height(522)
		.url();
	const schemaImage16x9 = getImageBuilder(pageData?.seo?.metaImage)
		?.quality(100)
		.width(696)
		.height(392)
		.url();

	const images = [schemaImage1x1, schemaImage4x3, schemaImage16x9].filter(
		notEmpty
	);

	const name = pageData?.title || pageData?.seo?.metaTitle;
	const structuredData: WithContext<CollectionPage> = {
		"@context": "https://schema.org",
		"@type": "CollectionPage",
		name: title ? `${title}` : name,
		description: description ? description : pageData?.seo?.metaDescription,
		image: images,
		publisher: {
			"@type": "Organization",
			name: "Aiven",
			logo: {
				"@type": "ImageObject",
				url: "https://aiven.io/assets/img/aiven-logo.png",
			},
		},
		url: baseUrl,
		mainEntity: {
			"@type": "ItemList",
			itemListElement: itemsList.map(
				(iterated: collectionListItem, i: number) => {
					const { name } = iterated;
					const item: ListItem = {
						"@type": "ListItem",
						position: i + 1,
					};

					if ("datePublished" in iterated) {
						item.item = {
							"@type": "Article",
							name: name,
							description: iterated.description,
							url: iterated.url,
							datePublished: iterated.datePublished
								? new Date(iterated.datePublished).toISOString()
								: "",
						};

						if (
							iterated.hasOwnProperty("author") &&
							iterated.author &&
							hasAuthors(iterated.author)
						) {
							item.item.author = iterated.author.map((i) => {
								return {
									"@type": "Person",
									name: i.name,
								};
							});
						}
					} else {
						item.item = {
							"@type": "Thing",
							name: name,
						};

						if ("url" in iterated) {
							item.item.url = iterated.url;
						}
						if ("description" in iterated) {
							item.item.description = iterated.description;
						}
					}
					return item;
				}
			),
		},
	};

	return {
		"script:ld+json": structuredData,
	};
}

function getTechArticleSchema(articleData: GlossaryArticle) {
	// https://developers.google.com/search/docs/data-types/article#article-types
	const schemaImage1x1 = getImageBuilder(articleData?.seo?.metaImage)
		?.quality(100)
		.width(696)
		.height(696)
		.url();
	const schemaImage4x3 = getImageBuilder(articleData?.seo?.metaImage)
		?.quality(100)
		.width(696)
		.height(522)
		.url();
	const schemaImage16x9 = getImageBuilder(articleData?.seo?.metaImage)
		?.quality(100)
		.width(696)
		.height(392)
		.url();

	const images = [schemaImage1x1, schemaImage4x3, schemaImage16x9].filter(
		notEmpty
	);
	const htmlContent = renderToString(
		<MarkDown
			shouldUseRemixRelatedContext={false}
			content={asText(articleData.body)}
		/>
	);

	const techArticleStructuredData: WithContext<TechArticle> = {
		"@context": "https://schema.org",
		"@type": "TechArticle",
		headline: asText(articleData.title),
		image: images,
		wordCount: htmlContent.replace(/<[^>]*>?/gm, "").split(" ").length,
		inLanguage: articleData.__i18n_lang,
		publisher: {
			"@type": "Organization",
			name: "Aiven",
			logo: {
				"@type": "ImageObject",
				url: "https://aiven.io/assets/img/aiven-logo.png",
			},
		},
		url: articleData.slug.current,
		articleBody: htmlContent,
	};

	return techArticleStructuredData;
}

function isAccordionSection(
	data: PageDocumentBase["content"][number]
): data is SanityKeyed<Accordion> {
	return data._type === "accordion";
}

function isEventListSection(
	data: PageDocumentBase["content"][number]
): data is SanityKeyed<EventList> {
	return data._type === "eventList";
}

export type StructuredDataTag = {
	"script:ld+json": WithContext<Thing> | Array<WithContext<Thing>> | undefined;
};

export function getStructuredData({
	pageData,
	baseUrl,
}: SchemaConfig): StructuredDataTag {
	const emptyStructuredData = {
		"script:ld+json": undefined,
	};

	if (!pageData) return emptyStructuredData;

	switch (pageData._type) {
		case "home": {
			if (!isPageDocument(pageData)) return emptyStructuredData;

			return {
				"script:ld+json": getPageSchema(pageData),
			};
		}
		case "post": {
			return {
				"script:ld+json": getBlogSchema(pageData as PostSingleQuery, baseUrl),
			};
		}
		case "devArticle": {
			return {
				"script:ld+json": getArticleSchema(pageData as DevArticle, baseUrl),
			};
		}
		case "event": {
			if (!isEventsPageDocument(pageData)) {
				return emptyStructuredData;
			}

			return {
				"script:ld+json": getEventSchema(pageData),
			};
		}
		case "glossaryArticle": {
			if (!isGlossaryArticleSingle(pageData)) {
				return emptyStructuredData;
			}

			return {
				"script:ld+json": getTechArticleSchema(pageData),
			};
		}
		default: {
			if (!isPageDocument(pageData)) return emptyStructuredData;

			const { content } = pageData;

			if (!content) {
				return emptyStructuredData;
			}

			const pageSchema = [];
			const schema = getPageSchema(pageData);
			if (schema) {
				pageSchema.push(schema);
			}

			// Add FAQ Schema
			const faqSection = content.find(
				(section) =>
					section._type === "accordion" && Boolean(section.isFaqAccordion)
			);

			if (faqSection && isAccordionSection(faqSection)) {
				const faqSchema = getFAQSchema(faqSection);

				pageSchema.push(faqSchema);
			}

			// Add EventList Schema
			const eventListSection = content.find(
				(section) => section._type === "eventList"
			);

			if (
				eventListSection &&
				isEventListSection(eventListSection) &&
				eventListSection.items
			) {
				const eventListSchema = getEventListSchema(eventListSection.items);

				pageSchema.push(eventListSchema);
			}

			return pageSchema.length > 0
				? {
						"script:ld+json": pageSchema,
					}
				: emptyStructuredData;
		}
	}
}
