import {
	sectionsFields,
	linkFields,
	cardListFields,
	navigationFields,
	portableTextField,
	imageFields,
	resourceTagFields,
} from "../queries/groq-fragment";
import groq from "groq";
import type { QueryParams } from "~/types";
import type { PostSingleQuery, ProductPageSingleQuery } from "~/types/queries";
import type {
	PageDocumentBase,
	CaseStudyPageDocument,
	PageSection,
	SanityDocument,
	ResourceDocument,
	PersonDocument,
	EventPageDocument,
	Link,
} from "~/types/sanity-schema";
import type { Language } from "./language";
import { getLocalePath } from "./language";
import type { DevHierarchy, DevArticle, Tag } from "~/types/dev-center";
import { pathFields } from "~/queries/dev-center.server";

export function isSanityIdEqual(id1?: string, id2?: string): boolean {
	if (!id1 || !id2) {
		return false;
	}

	const id1WithoutDraft = id1.replace(/^drafts\./, "");
	const id2WithoutDraft = id2.replace(/^drafts\./, "");
	return id1WithoutDraft === id2WithoutDraft;
}

function isDraft(doc: SanityDocument): boolean {
	return doc._id.startsWith("drafts.");
}

function getPublishedId(document: SanityDocument): string {
	return isDraft(document) ? document._id.slice(7) : document._id;
}

// "Replaces" published documents with drafts, if available.
export function overlayDrafts<T extends SanityDocument>(
	documents: Array<T>
): Array<T> {
	const overlayed = new Map<string, T>();

	documents.forEach((doc) => {
		const existing = overlayed.get(getPublishedId(doc));

		if (doc._id.startsWith("drafts.")) {
			// Drafts always overlay
			overlayed.set(getPublishedId(doc), doc);
		} else if (!existing) {
			// Published documents only override if draft doesn't exist
			overlayed.set(doc._id, doc);
		}
	});

	return Array.from(overlayed.values());
}

export function filterDataToSingleItem<T extends SanityDocument>(
	data: T | Array<T>,
	preview = false
): T {
	if (!Array.isArray(data)) {
		return data;
	}

	if (data.length === 1) {
		return data[0];
	}

	if (preview) {
		return data.find((item) => isDraft(item)) || data[0];
	}

	return data[0];
}

export function asLink(
	source: Link | undefined | null
): string | undefined | null {
	if (source?.externalUrl) {
		if (
			source.externalUrl.startsWith("#") ||
			source.externalUrl.startsWith("?m=") ||
			source.externalUrl.startsWith("?t=")
		) {
			return source.externalUrl;
		}

		// Sometimes, we have external links refering to local pages
		// in this cases, we should tranform them to relative links
		// this avoids the browser having to refresh the whole page when navigating between local pages
		try {
			const externalUrl = new URL(source.externalUrl);
			if (
				externalUrl.hostname === "aiven.io" &&
				!externalUrl.pathname.includes("/community/forum") &&
				!externalUrl.pathname.includes("/docs")
			) {
				return externalUrl.toString(); // The Forum and Docs sites are behind the NGINX proxy need to use absolute path
			}
			return source.externalUrl;
		} catch (error) {
			console.error("Error creating URL:", source.externalUrl, error);
			return null;
		}
	}

	if (source?.internalLink) {
		const { slug, locale } = source;

		// check if slug and locale exist
		if (!slug || !locale) {
			return undefined;
		}

		return getLocalePath(slug.current, locale);
	}
}

export function asText(source: string | null | undefined): string {
	return source ? source : "";
}

export function findPageSection<T extends PageSection>(
	data: PageDocumentBase,
	sectionKey: string
): T | undefined {
	const { content } = data;

	if (!content || content.length === 0) return undefined;

	const foundSection = content.find((section) => section._type === sectionKey);

	if (!foundSection) {
		return undefined;
	}

	return foundSection as T;
}

export const MIN_SINGLE_PAGE_SLUG_LENGTH = 15;

// A helper function to work out what query we should run based on this slug
export function getQueryFromSlug(
	lang: Language,
	slug: string,
	queryKey?: string
) {
	const slugArray = slug.split("/").filter((p) => p);

	// Keep extending this section to match the slug against the docQuery object keys
	const docQuery: Record<string, string> = {
		home: groq`*[_id match "*frontpage*" && __i18n_lang == $lang] {
			...,
			content[] {
				${sectionsFields}
			},
			modals[]->{
				...,
				content[] {
					${portableTextField}
				},
				cta {
					${linkFields}
				}
			},
			infoBanner->{
				...,
				primaryCTA {
					${linkFields}
				}
			}
    }`,
		page: groq`*[_type in ["page", "term", "timeSeries", "solution", "product"] && slug.current == $slug && __i18n_lang == $lang] {
      ...,
			"backgroundColor": backgroundColor->value,
			_type != "term" => {
				content[] {
					...,
					${sectionsFields}
				},
			},
			_type == "term" => {
				content[] {
					${portableTextField}
				}
			},
			_type == "product" => {
				service->{
					supportedVersion,
					externalId
				}
			},
			modals[]->{
				...,
				content[] {
					${portableTextField}
				},
				cta {
					${linkFields}
				}
			},
			infoBanner->{
				...,
				primaryCTA {
					${linkFields}
				}
			},
			secondaryNavigation->{
				${navigationFields}
			},
			tags[]->{
				_id,
				"title": coalesce(title[$lang], title.en),
				slug
			},
			internalTags[]->{
				title
			},
    }`,
		blog: groq`*[_type == "post" && slug.current == $slug && __i18n_lang == $lang] {
            ...,
            'categories': categories[]->{"title": coalesce(title[$lang], title.en), description, slug},
            'authors': authors[]->{
				name, 
				"bio": coalesce(bio[$lang], bio.en), 
				image, 
				"slug": slug.current
			}
        }`,
		press: groq`*[_type == "pressRelease" && slug.current == $slug && __i18n_lang == $lang] {
            ...,
            content[] {
                ...,
                markDefs[]{
                    ${linkFields}
                }
            }
        }`,
		"case-studies": groq`*[_type == "caseStudy" && slug.current == $slug && __i18n_lang == $lang] {
			...,
			"content": content[] {
				${sectionsFields}
			},
			company -> {
				${linkFields},
				hostProviders[] -> {
					...,
					cta {
							${linkFields}
					},
				},
				tags[]->{
					_type,
					_type == "industry" => {
						label,
						value
					},
					_type == "service" => {
						...,
						cta {
								${linkFields}
						},
					}
				}
			},
			additionalResources {
				${cardListFields}
			},
		}`,
		resources: groq`*[(_type in ["whitepaper", "ebook", "report", "webinar", "video", "workshop", "podcast"]) && slug.current == $slug && __i18n_lang == $lang] {
			...,
			resourceTag {
				${resourceTagFields}
			},
			asset {
				...,
				subtitle[] {
					${portableTextField}
				},
				content[] {
					...,
					${sectionsFields}
				},
				thankyouContent[] {
					...,
					${sectionsFields}
				},
				image {
					${imageFields}
				},
				"wistiaId": wistiaId.hashed_id,
			}
		}`,
		"dev-pages": groq`*[(_type in ["devArticle", "devCollection"]) && slug.current == $slug && __i18n_lang == $lang] {
			_id,
			__i18n_lang,
			_type,
			_updatedAt,
			title,
			subtitle,
			slug,
			body, 
			hierarchy->{
				slug,
				${pathFields}
			},
			tags[]->{
				_id,
				"title": coalesce(title[$lang], title.en),
				slug
			},
			internalTags[]->{
				title
			},
			feature,
			seo,
			publishedAt,
			_type == "devCollection" => {
				articleItems[]->{
					...,
				},
			},
		}`,
		devrel: groq`*[_type == 'person' && slug.current == $slug && role == 'dev-rel' && __i18n_lang == $lang] {
			...,
			"authorSlug": author->slug,
			image {
				${imageFields}
			}
		}`,
		advocates: groq`*[_type == 'person' && slug.current == $slug && role == 'community-advocates' && __i18n_lang == $lang] {
			...,
			"authorSlug": author->slug,
			image {
				${imageFields}
			}
		}`,
		events: groq`*[_type == 'event' && slug.current == $slug && __i18n_lang == $lang] {
			...,
			_id,
			title,
			subtitle,
			slug,
			seo,
			pageType{
				...,
				image {
					${imageFields}
				},
				"backgroundVideo": backgroundVideo.asset->url,
				primaryCTA {
					${linkFields}
				},
				partnersImage {${imageFields}},
				content[] {
					${sectionsFields}
				},
				thankyouMessage[] {
					${portableTextField}
				},
				thankyouContent[] {
					${sectionsFields}
				}
			},
			tags[]->{
				_id,
				"title": coalesce(title[$lang], title.en),
				slug
			},
			talks[]{speakers[]->{
				name,
				jobTitle, 
			}}
		}`,
		glossary: groq`*[_type == "glossaryArticle" && slug.current == $slug && __i18n_lang == $lang] {
            ...,
        }
		`,
	};

	let docQueryKey = "page";

	if (slugArray.length === 0) {
		return {
			docQueryKey: "home",
			queryParams: { lang },
			query: docQuery.home,
		};
	}

	// Figure out the correct query key based on slug
	const [slugStart, slugEnd] = slugArray;

	// There is a slug conflict of press page.
	// We don't know which slug is a page or a press release single since the slug structure looks the same
	// eg: press/contact vs /press/larry-irvin-joins-aiven-executive-team.
	// Therefore, we decided to check the slug length to differentiate between them
	// Update MIN_SINGLE_PAGE_SLUG_LENGTH to whatever makes sense to us
	const isPressSinglePage =
		slugStart === "press" &&
		slugArray.length > 1 &&
		slugEnd.length > MIN_SINGLE_PAGE_SLUG_LENGTH;

	const isSinglePage =
		docQuery.hasOwnProperty(slugStart) &&
		slugStart !== "press" &&
		slugArray.length > 1;

	if (queryKey) {
		docQueryKey = queryKey;
	} else if (isPressSinglePage) {
		docQueryKey = "press";
	} else if (isSinglePage) {
		docQueryKey = slugStart;
	} else {
		docQueryKey = `page`;
	}

	// We now have to re-combine the slug array to match our slug in Sanity.
	const queryParams: QueryParams = {
		slug: `/${slugArray.join("/")}`,
		lang,
	};

	return {
		queryParams,
		query: docQuery[docQueryKey],
	};
}

export function isPageDocument(data: SanityDocument): data is PageDocumentBase {
	return "content" in data && "seo" in data;
}

export function isProductPageDocument(
	data: PageDocumentBase
): data is ProductPageSingleQuery {
	return data._type === "product";
}

export function isCaseStudyPageDocument(
	data: PageDocumentBase
): data is CaseStudyPageDocument {
	return data._type === "caseStudy";
}

export function isPostSingle(data: SanityDocument): data is PostSingleQuery {
	return data._type === "post";
}

export function isDevCollectionSingle(
	data: SanityDocument
): data is DevArticle {
	return data._type === "devCollection";
}

export function isDevRelSingle(data: SanityDocument): data is PersonDocument {
	return data._type === "person" && "role" in data && data.role === "dev-rel";
}

export function isCommunityAdvocatesSingle(
	data: SanityDocument
): data is PersonDocument {
	return (
		data._type === "person" &&
		"role" in data &&
		data.role === "community-advocates"
	);
}

export function isDevHierarchy(data: SanityDocument): data is DevHierarchy {
	return data._type === "devHierarchy";
}

export function isDevPageSingle(data: SanityDocument): data is DevArticle {
	return data._type === "devArticle" || data._type === "devCollection";
}

export function isTag(data: SanityDocument): data is Tag {
	return data._type === "genericTag";
}

export function isEventsPageDocument(
	data: SanityDocument
): data is EventPageDocument {
	return data._type === "event";
}

export function isGlossaryArticleSingle(
	data: SanityDocument
): data is PostSingleQuery {
	return data._type === "glossaryArticle";
}

export const RESOURCE_DOCUMENT_TYPES = [
	"whitepaper",
	"ebook",
	"report",
	"webinar",
	"video",
	"podcast",
	"workshop",
	"page",
] as const;

export function isResourcesDocument(
	data: SanityDocument
): data is ResourceDocument {
	return RESOURCE_DOCUMENT_TYPES.includes(
		data._type as (typeof RESOURCE_DOCUMENT_TYPES)[number]
	);
}
