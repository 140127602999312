const spacerSizes = {
	layout1: "my-layout1",
	layout2: "my-layout2",
	layout3: "my-layout3",
	layout4: "my-layout3 md:my-layout4",
	layout5: "my-layout3 md:my-layout5",
	layout6: "my-layout3 md:my-layout6",
	layout7: "my-layout3 md:my-layout7",
};

interface Props {
	size: keyof typeof spacerSizes;
	className?: string;
}

export function Divider({ size, className = "" }: Props) {
	return (
		<div
			className={`mx-auto h-[1px] bg-grey-10 ${className} ${spacerSizes[size]}`}
		/>
	);
}
