import clsx from "clsx";
import type { LinkProps } from "./link";
import { Link } from "./link";

export type IconLinkProps = {
	iconLeft?: JSX.Element;
	iconRight?: JSX.Element;
};

export function IconLink({
	children,
	prefetch = "intent",
	className,
	to,
	color,
	iconLeft,
	iconRight,
	...rest
}: IconLinkProps & LinkProps) {
	return (
		<Link
			prefetch={prefetch}
			to={to}
			className={clsx(
				"text-primary group/link inline-flex w-fit items-center gap-2 rounded border border-transparent bg-transparent font-medium no-underline decoration-theme-primary underline-offset-8 transition-colors duration-200 ease-in-out hover:underline focus-visible:outline-blue-40 disabled:text-grey-30 group-hover/link:underline",
				className
			)}
			{...rest}
		>
			{iconLeft ? iconLeft : null}
			{children}
			{iconRight ? iconRight : null}
		</Link>
	);
}
