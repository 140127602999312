import defaultMetaImage from "../images/aiven-meta-preview.jpg";
import { removeTrailingSlash } from "~/utils/misc";
import type { ImageProps } from "~/components/ui/image";

export const defaultMetaData = {
	siteUrl: "https://aiven.io",
	name: "Aiven",
	title: "Aiven - Your Trusted Data & AI Platform",
	url: "https://aiven.io",
	description:
		"Aiven simplifies cloud data infrastructure management by deploying open-source technologies across multiple clouds, enabling fast and confident creation of next-generation applications.",
	twitter: "@aiven_io",
	facebook: "https://www.facebook.com/aivencloud/",
	email: "sales@aiven.io",
	image: defaultMetaImage,
	keywords: "",
	locale: "en_US",
};

type SeoMeta = {
	origin: string;
	url: string;
	image?: ImageProps;
	title?: string | null;
	description?: string | null;
	keywords?: string;
	locale?: string;
};

export function getSocialMetas({
	origin,
	title,
	description,
	keywords,
	image,
	url: metaUrl,
	locale,
}: SeoMeta) {
	const metaTitle = title || defaultMetaData.title;
	const metaDescription = description || defaultMetaData.description;
	const metaImage =
		image?.src || `${removeTrailingSlash(origin)}${defaultMetaData.image}`;
	const metaImageAlt = image?.alt || defaultMetaData.title;
	const metaKeyword = keywords || defaultMetaData.keywords;
	const metaLocale = locale || defaultMetaData.locale;

	return [
		// General Tags
		{ title: metaTitle },
		{ name: "description", content: metaDescription },
		{ name: "keywords", content: metaKeyword },
		{ name: "image", content: metaImage },

		// OgTags
		{ property: "og:site_name", content: defaultMetaData.name },
		{ property: "og:url", content: metaUrl },
		{ property: "og:locale", content: metaLocale },
		{ property: "og:title", content: metaTitle },
		{ property: "og:description", content: metaDescription },
		{ property: "og:image", content: metaImage },
		{ property: "og:image:alt", content: metaImageAlt },
		{ property: "og:email", content: defaultMetaData.email },

		// Social Tags
		{
			name: "twitter:card",
			content: metaImage ? "summary_large_image" : "summary",
		},
		{ name: "twitter:title", content: metaTitle },
		{ name: "twitter:description", content: metaDescription },
		{ name: "twitter:image", content: metaImage },
		{ name: "twitter:site", content: defaultMetaData.twitter },
		{ name: "twitter:alt", content: metaImageAlt },
		{ name: "twitter:domain", content: metaUrl },
	];
}

export function sendXML(xml: string, maxAge = 0) {
	return new Response(xml, {
		headers: {
			"Cache-Control": `public, max-age=${maxAge}, s-maxage=${maxAge}`,
			"Content-Type": "application/xml",
			"Content-Length": String(Buffer.byteLength(xml)),
		},
	});
}

export function getFeedCopyright() {
	return `All rights reserved ${new Date().getFullYear()}, Aiven`;
}
