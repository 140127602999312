import clsx from "clsx";
import { Paragraph } from "./ui/typography";
import { Icon } from "./ui/icons";
import type { IconColors } from "./ui/icons";

export type AlertType = "info" | "success" | "warning";

export type AlertProps = {
	type?: AlertType;
	title?: string;
	margin?: "my-layout3" | "none";
	children: React.ReactNode;
};

type AlertTypes = {
	[key in AlertType]: { color: IconColors };
};

const iconColor: AlertTypes = {
	info: {
		color: "info",
	},
	success: {
		color: "success",
	},
	warning: {
		color: "warning",
	},
};

export function Alert({
	type = "info",
	margin = "my-layout3",
	title,
	children,
}: AlertProps) {
	return (
		<div
			className={clsx(
				`${margin !== "none" && margin} flex gap-3 rounded-sm p-4`,
				{
					"bg-blue-0": type === "info",
					"bg-green-0": type === "success",
					"bg-yellow-0": type === "warning",
				}
			)}
		>
			<div>
				<Icon
					name="infoFill"
					width="20"
					height="24"
					color={iconColor[type].color}
				/>
			</div>
			<div>
				{title ? (
					<Paragraph fontWeight="font-semibold" className="mb-2">
						{title}
					</Paragraph>
				) : null}
				{children ? (
					typeof children === "string" ? (
						<Paragraph size="body-small" color="secondary">
							{children}
						</Paragraph>
					) : (
						children
					)
				) : null}
			</div>
		</div>
	);
}
